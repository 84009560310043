import { AppBar, makeStyles, Toolbar } from "@material-ui/core"

import { Logo } from "./Logo"
import {LoginButton} from "../blockchain_components/Login/LoginButton";
import {WalletModal} from "../blockchain_components/Wallet/WalletModal";

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: theme.palette.background.default,
    },
    toolBar: {
        justifyContent: 'space-between', alignItems: 'center', height: 100
    }
}))

export const Header = () => {
    const classes = useStyles()
    return <AppBar position='fixed' elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
            <Logo />
            <WalletModal/>
            <LoginButton />
        </Toolbar>
    </AppBar>
}