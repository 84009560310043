import {createReducer} from "@reduxjs/toolkit";
import {setOpenModal} from "./actions";


const initialState = {
    openModal: null
}


export default createReducer(initialState, builder =>
    builder
        .addCase(setOpenModal, (state, action) => {
            state.openModal = action.payload
        })
)
