import { makeStyles, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    cardContainer: {
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
    },
    titleSpacer:{
        height:20
    }
}))

export const PitchCard = ({ title, text }) => {
    const classes = useStyles()
    return <div className={classes.cardContainer}>
      <Typography variant='h4'>{title}</Typography>
      <div className={classes.titleSpacer}/>
      <Typography align='justify' variant='body1' style={{whiteSpace:'pre-line'}}>{text}</Typography>
    </div>
  }