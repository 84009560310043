import { Button, Link, Typography } from "@material-ui/core"
import translator from "../utility/translator"

export const BuyRoomCTA = () => {
  return <Link href={'https://foundation.app/massimocolonna/ethereal-shapes-20453'} style={{ textDecoration: 'none', width: '100%' }} target='_blank' rel='noopener'>
    <Button fullWidth>
      <Typography
        variant='h5'
        color='textPrimary'
        style={{
          fontWeight: 600,
        }}
      >
        {translator.fromLabel('home_buyRoom_cta')}
      </Typography>
    </Button>
  </Link>
}