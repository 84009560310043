
const faqs = {
  faq_howDoI_question: "How do I become owner of the space?",
  faq_howDoI_answer: "You will have to purchase the related NFT of the artist linked in the space's information.",
  faq_ifOwner_question: "If I am the owner, how can I showcase NFTs collected in my wallet?",
  faq_ifOwner_answer: "Please contact us in order to receive instructions on how to proceed.",
  faq_canISell_question: "Can I sell my NFTs through the space I own?",
  faq_canISell_answer: "You may use the space to advertise your NFTs. Once clicked on them you will be sent to the original sales page.",
  faq_whatIfResell_question: "What if I resell the related NFT of the artist representing the space on the secondary market?",
  faq_whatIfResell_answer: "The new collector will become owner of the space and will get exclusive access to use it as a gallery.",
  faq_virtualReality_question: "Can I navigate in Virtual Reality?",
  faq_virtualReality_answer: "We are working on this implementation and it will be soon becoming VR ready.",
  faq_generalInfo_question: 'General information',
  faq_generalInfo_answer: 'This is an evolving project. We have great plans for the nearest future. Each space is online for the period of one year, renewable for free from the current owner or from a new owner of secondary sale. Contact us for further information.',
}

const roomPlayer = {
  roomPlayer_loading_text: "Loading, please wait...",
  roomPlayer_loadingSlow_text: "Your connection seems a little slow, loading could take a bit longer",
  roomPlayer_loadingVerySlow_text: "Your connection seems very slow, loading could take several minutes",
}

const home = {
  home_openRoom_cta: "View Space",
  home_designedBy_text: "This space is designed by",
  home_massimoColonna_name: "MASSIMO COLONNA",
  home_massimoColonna_text: "Massimo Colonna was born in Scandiano in 1986. Beyond photography, he developed a passion for the new technologies for creating images, such as 3D rendering and CGI, with which he creates and invents new spaces and shapes to show his imagination. Abstract landscapes, surreal settings and places that develop near and far from reality are his favorite subjects.",
  home_faq_title: 'FAQs',
  home_description_title: "The project",
  home_description_text: "OwnShow is the first ever Cryptoart project offering the possibility of virtually navigating inside a unique digital artwork. Each space is curated and designed by renowned creators. Anyone can visit the space but only the collector who owns the related NFT sold by the artist will get exclusive access: It can become your personal gallery, showcasing other collected artworks.",
  home_companyName_text: "OwnShow.space",
  home_haveALook_text: "Like it?",
  home_buyRoom_cta: "OWN IT",
  home_nftEdition_text: "NFT Edition 1/1 purchasable on Foundation.app",
  home_contactUs_label: "contact us",
  home_copyright_text: "OwnShow. All Rights Reserved.",
}

const blockchain = {
  blockchain_connect_button: "Connect Wallet"
}

export const english = {
  ...home,
  ...roomPlayer,
  ...faqs,
  ...blockchain
}