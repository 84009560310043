import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText, makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import React from "react";
import {useWeb3React} from "@web3-react/core";
import {getContract} from "../../blockchain_utility/Contract";
import ERC_721 from "../../blockchain_utility/smart_contracts_abi/IERC721.json";

const useStyles = makeStyles(() => ({
    table: {
        width:'100%',
    },
    link:{
        '&:visited': {
            color:'blue'
        },
    }
}));

const asyncFilter = async (arr, predicate) =>
    arr.reduce(async (memo, e) =>
            await predicate(e) ? [...await memo, e] : memo
        , []);

export const NftList = ({
    contractAddresses = [],
    tokenIds = {},
                        }) => {
    const classes = useStyles();
    const {account, library } = useWeb3React();
    const [isError, setError] = React.useState(false);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
        initializeData()
        // eslint-disable-next-line
    }, []);

    const initializeData = async () => {
        const nftRows = [];
        for(const contractAddress of contractAddresses) {
            const contract = getContract(contractAddress, ERC_721.abi, library, account);
            const contractTokenIds = tokenIds[contractAddress];
            const v = await asyncFilter(contractTokenIds, async (v) => {
                try{
                    const address = await contract.ownerOf(v);
                    //TODO used for testing... remove on production
                    // return address.toUpperCase() === '0xcda72070e455bb31c7690a170224ce43623d0b6f'.toUpperCase()
                    return address.toUpperCase() === account.toUpperCase()
                }catch(error){
                    setError(true);
                }
            });
            v.forEach((tokenId) => nftRows.push(
                <TableRow key={tokenId}>
                    <TableCell component="th" scope="row">
                        {tokenId}
                    </TableCell>
                    <TableCell align="right">{<a className={classes.link} href={`https://etherscan.io/token/${contractAddress}?a=${tokenId}`} target="_blank" rel="noreferrer">View on Etherscan</a>}</TableCell>
                </TableRow>
            ));
        }
        setRows(nftRows)
    }

    if(isError){
        const handleClose = () => setError(false);
        return(
            <Dialog
                open={isError}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Error, probably the wrong network has been chosen, make sure on Metamask that you are connected to the Mainnet.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="black">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
    if(rows.length === 0)
        return <div style={{textAlign:'center',padding:'5px'}}>Your NFT tokens will appear here...</div>
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Token Id</TableCell>
                        <TableCell align="right">Etherscan</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>
    )
}