import {InjectedConnector} from '@web3-react/injected-connector'

export const chainIdMap = {
    Mainnet: 1,
    Ropsten: 3,
    Rinkeby: 4,
    Goerli: 5,
    Kovan: 42,
}

export const formatConnectorName = (connector) => {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    return Object.keys(SUPPORTED_WALLETS)
        .filter(
            k =>
                SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
        )
        .map(k => SUPPORTED_WALLETS[k].name)[0]
}

export const injected = new InjectedConnector({
    supportedChainIds: [
        chainIdMap['Mainnet'],
        chainIdMap['Ropsten'],
        chainIdMap['Rinkeby'],
        chainIdMap['Goerli'],
        chainIdMap['Kovan'],
    ],
})

export const SUPPORTED_WALLETS = {
    METAMASK: {
        connector: injected,
        name: 'MetaMask',
        iconName: 'metamask.png',
        description: 'Easy-to-use browser extension.',
        href: null,
        color: '#E8831'
    }
}







