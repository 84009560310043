import {getAddress} from "@ethersproject/address";

export const isAddress = (value) => {
    try {
        return getAddress(value)
    } catch {
        return false
    }
}

export const shortenAddress = (address, chars = 4) => {
    const parsed = isAddress(address);
    if (!parsed) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}