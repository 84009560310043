import { CircularProgress, Typography } from "@material-ui/core";
import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import translator from "../utility/translator";


const normal = "normal"
const slow = "slow"
const verySlow = "verySlow"

const getSpeedMessage = speed => {
  switch (speed){
    case normal:
      return translator.fromLabel('roomPlayer_loading_text')
    case slow:
      return translator.fromLabel('roomPlayer_loadingSlow_text')
    case verySlow:
      return translator.fromLabel('roomPlayer_loadingVerySlow_text')
    default:
      throw Error(`Unknown speed: ${speed}`)
  }
}

const LoadingComponent = ({progress, considerSlowAfterSeconds=20, considerVerySlowAfterFurtherSeconds=40}) => {

  const [measuredSpeed, setMeasuredSpeed] = React.useState(normal)

  React.useEffect(() => {
    let currentTimeout = setTimeout(
      () => {
        setMeasuredSpeed(slow)
        currentTimeout = setTimeout(
          () => {
            setMeasuredSpeed(verySlow)
          },
          considerVerySlowAfterFurtherSeconds * 1000
        )
      },
      considerSlowAfterSeconds * 1000
    )
    return () => clearTimeout(currentTimeout)
  }, [setMeasuredSpeed, considerSlowAfterSeconds, considerVerySlowAfterFurtherSeconds])

  // TODO use classes
  return <div style={{display:'flex', flexDirection: 'column', position:'absolute', alignItems:'center', justifyContent:'center', width: '100vw', height: '100vh'}}>
    <CircularProgress variant={progress ? "determinate" : 'indeterminate'} color='black' value={progress * 100} />
    <div style={{height:30}}/>
    <div style={{width: '80%'}}>
    <Typography align='center' variant='h5'>{getSpeedMessage(measuredSpeed)}</Typography>
    </div>
  </div>
}

// TODO dimezzare il logo
// TODO lifecycle?
export const RoomPlayer = () => {
  const [loading, setLoading] = React.useState(true)
  const [progress, setProgress] = React.useState(null)

  const unityContext = React.useMemo(() => {
    const ctx = new UnityContext({
    loaderUrl: "unity-app/Build/unity-app.loader.js",
    dataUrl: "unity-app/Build/unity-app.data",
    frameworkUrl: "unity-app/Build/unity-app.framework.js",
    codeUrl: "unity-app/Build/unity-app.wasm",
    })
    ctx.on('OpenUrl', url => { window.location.href = url })
    return ctx
  }, [])

  React.useEffect(() => {
    unityContext.on('progress', setProgress)
    return () => unityContext.on('progress', () => {})
  }, [unityContext])

  React.useEffect(()=> {
    unityContext.on('loaded', () => setLoading(false))
    return () => unityContext.on('loaded', () => {})
  }, [unityContext])

  return <>
    {loading ? <LoadingComponent progress={progress}/> : null}
    <Unity devicePixelRatio={1} style={{ position:'absolute', width: '100vw', height: '100vh' }} unityContext={unityContext} />
    </>
}