import { Button, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"
import translator from "../utility/translator"
import { useLocation } from "react-router"
import { Spacer } from "./Spacer"

export const OpenRoomCTA = ({ style }) => {
    const location = useLocation()

    if (location.pathname === '/massimo-colonna')
        return null

    // TODO remove this after april 9
    if (new Date().getTime() < new Date(2021, 3, 9, 12).getTime())
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', ...style }}>
                <Button disabled fullWidth>
                    <Typography
                        variant='h5'
                        color='textSecondary'
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        {translator.fromLabel('home_openRoom_cta')}
                    </Typography>
                </Button>
                <Spacer height={10} />
                <Typography color='textSecondary' variant='h6' align='center'>ONLINE ON APR 9</Typography>
            </div>
        )
    return (
        <Link to={'/massimo-colonna'} style={{ textDecoration: 'none', ...style }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textDecoration: 'none', ...style }}>
                <Button disabled fullWidth>
                    <Typography
                        variant='h5'
                        color='textPrimary'
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        {translator.fromLabel('home_openRoom_cta')}
                    </Typography>
                </Button>
            </div>
        </Link>
    )
}
