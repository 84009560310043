import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Router, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import {createWeb3ReactRoot, Web3ReactProvider} from "@web3-react/core";
import {getLibrary} from "./blockchain_utility/Library";
import {Provider} from "react-redux";
import store from "./state";
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import firebase from "firebase";

const Web3ProviderNetwork = createWeb3ReactRoot('NETWORK');

var firebaseConfig = {
    apiKey: "AIzaSyD9KPIFGWEuF0QCTnO_Z6iQZUhbj-Pxu0Q",
    authDomain: "own-show-test.firebaseapp.com",
    databaseURL: "https://own-show-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "own-show-test",
    storageBucket: "own-show-test.appspot.com",
    messagingSenderId: "716282150706",
    appId: "1:716282150706:web:385c02f270e295fa65a12b"
};

export const history = createBrowserHistory()
ReactDOM.render(
    // Blockchain libraries must be at a higher level than the Router
    <Web3ReactProvider getLibrary ={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
            <FirebaseDatabaseProvider {...firebaseConfig} firebase={firebase}>
                <Provider store={store}>
                    <Router history={history}>
                      <Route component={App} />
                  </Router>
                </Provider>
            </FirebaseDatabaseProvider>
        </Web3ProviderNetwork>
    </Web3ReactProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
