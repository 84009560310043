import {isAddress} from "./Address";
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import ERC_721 from './smart_contracts_abi/IERC721.json'

const useActiveWeb3React = () => {
    const context = useWeb3ReactCore();
    const contextNetwork = useWeb3ReactCore('NETWORK');
    return context.active ? context : contextNetwork
}

const getSigner = (library, account) => {
    return library.getSigner(account).connectUnchecked()
}

const getProviderOrSigner = (library, account) => {
    return account ? getSigner(library, account) : library
}

export const getContract = (address, ABI, library, account) => {
    if (!isAddress(address) || address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }

    return new Contract(address, ABI, getProviderOrSigner(library, account))
}

const useContract = (address, ABI, withSignerIfPossible) => {
    const { library, account } = useActiveWeb3React()

    if (!address || !ABI || !library) return null;
    try {
        return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
        return null
    }
}

export const useNftContract = (tokenAddress, withSignerIfPossible) => {
    return useContract(tokenAddress, ERC_721.abi, withSignerIfPossible);
}