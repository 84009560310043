import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {ApplicationModal, setOpenModal} from "./actions";


export function useModalOpen(modal) {
    const openModal = useSelector((state) => state.application.openModal)
    return openModal === modal
}

export function useToggleModal(modal){
    const open = useModalOpen(modal)
    const dispatch = useDispatch()
    return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open])
}

export function useOpenModal(modal){
    const dispatch = useDispatch()
    return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal])
}

export function useCloseModals(){
    const dispatch = useDispatch()
    return useCallback(() => dispatch(setOpenModal(null)), [dispatch])
}

export function useWalletModalToggle(){
    return useToggleModal(ApplicationModal.WALLET)
}