import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import React from "react";
import { Route, Switch } from 'react-router-dom'


import { Header } from "./components/Header";
import { HomePage } from "./views/HomePage";
import { RoomPlayer } from "./views/RoomPlayer";

// TODO use the actual theme background everywhere!
const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    background: { paper: '#fafafa', default: '#fff', nftList:'#EDF1F3' },
    secondary: {
      main: '#fafafa',
      contrastText: '#000',
    },
    primary: {
      main: '#fff',
      contrastText: '#000',
    },
    error: {
      main: '#ba261a',
      contrastText: '#fff',
    },
  },
  typography:
  {
    fontFamily: "'Questrial', sans-serif",
    color: 'white',
  },
  overrides: {
    MuiButton: {
      root: {
        border: '1px solid black'
      }
    },
  },

})


// TODO app scrolls horizontally o.o
const App = () => {
  const theme = lightTheme

  return <ThemeProvider theme={theme}>
    <div style={{overflowX: 'hidden'}}>
    <Header />
    <Switch>
      <Route
        exact
        path='/'
        render={(props) => <HomePage />}
      />
      <Route
        exact
        path='/massimo-colonna'
        render={(props) => (
          <RoomPlayer />
        )}
      />
    </Switch>
    </div>
  </ThemeProvider>
};

export default App;