import React from 'react'
import {chainIdMap} from "../../blockchain_utility/Wallet";
import { useWeb3React } from '@web3-react/core';
import {shortenAddress} from "../../blockchain_utility/Address";
import {Button, Typography} from "@material-ui/core";
import translator from "../../utility/translator";
import {useWalletModalToggle} from "../../state/application/hooks";
import Jazzicon from "react-jazzicon";

export const LoginButton = () => {

    const { chainId, active, account } = useWeb3React()

    const toggleWalletModal = useWalletModalToggle()

    if(active){
        return (
            <Button
                style={{height: 60, width: 200}}
                onClick={toggleWalletModal}>
                <div style={{color: "black", display: "flex", flexDirection: "column"}}>
                    <Typography variant='h6' style={{fontWeight: 600}}>{chainIdMap.Mainnet === chainId ? "MAINNET": "Wrong Network"}</Typography>
                    <div style={{display: "flex", alignItems:'center'}}>
                        <Jazzicon diameter={20} seed={parseInt(account.slice(2,10),16)} />
                        &nbsp;
                        <Typography variant='h6' style={{fontWeight: 400}}>{shortenAddress(account)}</Typography>
                    </div>
                </div>
            </Button>
        );
    }
    return (

        <Button
            style={{height: 60, width: 200}}
            onClick={toggleWalletModal}>
            <Typography
                variant='h6'
                style={{
                    fontWeight: 600,
                }}
            >
                {translator.fromLabel('blockchain_connect_button')}
            </Typography>
        </Button>
    )
}



