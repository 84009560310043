import { english } from '../lang/english.js'

class Translator {
  constructor() {
    // TODO? this.language = navigator.language.includes('it') ? italian : english
    this.language = english
    this.unmatchedLabels = new Set()
    this.printUnmatchedLabels = false // set to true to debug missing label
  }

  setLanguage = (language) => {
    switch (language) {
      // TODO? italian
      default:
        this.language = english
    }
  }

  fromLabel = (label) => {
    if (this.language[label])
      return this.language[label]
    if (this.printUnmatchedLabels) {
      this.unmatchedLabels.add(label)
      console.log('unmatchedLabels', this.unmatchedLabels)
    }
    return label
  }
}

export default new Translator()
