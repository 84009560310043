import React from 'react'
import translator from "../utility/translator";
import { OpenRoomCTA } from '../components/OpenRoomCTA'
import { Avatar, IconButton, Link, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { PitchCard } from '../components/PitchCard'
import { BuyRoomCTA } from '../components/BuyRoomCTA'
import { Footer } from '../components/Footer'
// TODO split
import { Instagram, Twitter, PlayCircleFilled } from '@material-ui/icons';
import { Spacer } from '../components/Spacer';


const Triplet = ({ left = null, right = null, center = null }) => {
    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', flex: 1, height: '100%' }}>{left}</div>
        <div style={{ display: 'flex', flexDirection: 'column', width: 325, height: '100%', alignItems: 'center', justifyContent: 'space-between' }}>{center}</div>
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1, height: '100%' }}>{right}</div>
    </div>
}

const ArtistMobile = () => {
    const theme = useTheme()

    return <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Triplet
            left={null}
            center={<OpenRoomCTA style={{ flex: 1, width: '100%' }} />}
            right={null}
        />
        <Spacer height={50} />
        <Triplet
            left={null}
            center={<div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                {translator.fromLabel('home_designedBy_text').split(' ').map(word => <Typography color='textSecondary' variant='h5'>{word}</Typography>)}
            </div>}
            right={null}
        />
        <Spacer height={20} />
        <Triplet
            left={null}
            center={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar src='/Massimo_Colonna_logo__400x400.jpeg' style={{ height: 75, width: 75 }} />
                    <Spacer height={10} />
                    <div style={{
                        display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        {translator.fromLabel('home_massimoColonna_name').split(' ').map(word => <Typography variant='h4'>{word}</Typography>)}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75 }}>
                        <Link href='https://twitter.com/massssssimo' target='_blank' rel='noopener'>
                            <IconButton size='small' style={{ margin: '0px 5px' }}><Twitter style={{ color: theme.palette.primary.contrastText }} /></IconButton>
                        </Link>
                        <Link href='https://www.instagram.com/m.a.s.s.i.m.o.c.o.l.o.n.n.a' target='_blank' rel='noopener'>
                            <IconButton size='small' style={{ margin: '0px 5px' }}><Instagram style={{ color: theme.palette.primary.contrastText }} /></IconButton>
                        </Link>
                    </div>
                </div>
            }
            right={null}
        />
        <Spacer height={30} />
        <Typography align='justify' variant='body1'>{translator.fromLabel('home_massimoColonna_text')}</Typography>
    </div>
}

const Artist = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    // TODO style classes
    return mobile ? <ArtistMobile /> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Triplet
            left={null}
            center={<OpenRoomCTA style={{ flex: 1, width: '100%' }} />}
            right={null}
        />
        <Spacer height={50} />
        <Triplet
            left={null}
            center={<div style={{ display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                {translator.fromLabel('home_designedBy_text').split(' ').map(word => <Typography color='textSecondary' variant='h5'>{word}</Typography>)}
            </div>}
            right={null}
        />
        <Triplet
            left={<Avatar src='/Massimo_Colonna_logo__400x400.jpeg' style={{ height: 75, width: 75 }} />}
            center={<div style={{
                display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'nowrap', alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                {translator.fromLabel('home_massimoColonna_name').split(' ').map(word => <Typography variant='h4'>{word}</Typography>)}
            </div>}
            right={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 75, width: 75 }}>
                <Link href='https://twitter.com/massssssimo' target='_blank' rel='noopener'>
                    <IconButton size='small' style={{ margin: '0px 5px' }}><Twitter style={{ color: theme.palette.primary.contrastText }} /></IconButton>
                </Link>
                <Link href='https://www.instagram.com/m.a.s.s.i.m.o.c.o.l.o.n.n.a' target='_blank' rel='noopener'>
                    <IconButton size='small' style={{ margin: '0px 5px' }}><Instagram style={{ color: theme.palette.primary.contrastText }} /></IconButton>
                </Link>
            </div>}
        />
        <Spacer height={30} />
        <Typography align='justify' variant='body1'>{translator.fromLabel('home_massimoColonna_text')}</Typography>
    </div>
}

const LikeItOwnIt = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
        <Triplet
            center={
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='h4'>{translator.fromLabel('home_haveALook_text')}</Typography>
                    <Spacer height={10} />
                    <BuyRoomCTA />
                </div>
            }
        />
        <Spacer height={10} />
        <Typography color='textSecondary' variant='h6' align='center'>{translator.fromLabel('home_nftEdition_text')}</Typography>
    </div>
}

const QA = ({ code }) => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='body1' align='justify'><strong>{translator.fromLabel(`faq_${code}_question`)}</strong></Typography>
        <Spacer height={3}/>
        <Typography variant='body1' align='justify'>{translator.fromLabel(`faq_${code}_answer`)}</Typography>
        <Spacer height={15}/>
    </div>
}

const Faq = () => {
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='h4' style={{alignSelf:'center' }}>{translator.fromLabel(`home_faq_title`)}</Typography>
        <Spacer height={20}/>
        {["howDoI", "ifOwner", "canISell", "whatIfResell", "virtualReality", "generalInfo"].map(code => <QA code={code} />)}
    </div>
}

const Details = () => {
    return <div style={{ width: '100%' }}>
        <PitchCard title={translator.fromLabel('home_description_title')} text={translator.fromLabel('home_description_text')} />
        <Spacer height={80} />
        <Faq />
    </div>
}

const RoomVideo = () => {
    const [isPlaying, setIsPlaying] = React.useState(false)

    const video = React.useRef()
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%', overflow: 'hidden', padding: '0px 24px' }}>
        <video
            ref={video}
            loop
            poster='/video-poster.jpg'
            style={{ width: '100%', maxHeight: 720 }}
            onClick={() => { if (isPlaying) { setIsPlaying(false); video.current.pause() } }}
        >
            <source src="/demo-massimo-colonna-4.mp4" type="video/mp4" />
        </video>
        {
            isPlaying ? null : <IconButton style={{ position: 'absolute' }} onClick={() => { setIsPlaying(true); video.current.play() }}>
                <PlayCircleFilled style={{ fontSize: 81, color: 'white' }} />
            </IconButton>
        }
    </div>
}
export const HomePage = () => {
    //const screenshots = [Screenshot(screen1), Screenshot(screen2), Screenshot(screen3)]
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('sm'))

    // TODO style classes
    return <div style={{ width: '100%' }}>
        <div style={{
            marginLeft: 'auto', marginRight: 'auto',
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: 1080,
            marginTop: 100
        }}>
            <RoomVideo />
            <Spacer height={45} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: mobile ? '90%' : '60%', padding: '0px 24px' }}>
                <Artist />
                <Spacer height={80} />
                <LikeItOwnIt />
                <Spacer height={80} />
                <Details />
            </div>
            <Spacer height={45} />

            <Footer />
        </div>
    </div>
}