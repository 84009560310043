import {
    Fade,
    makeStyles,
    Modal,
    Typography
} from "@material-ui/core";
import {useCloseModals, useModalOpen} from "../../state/application/hooks";
import {ApplicationModal} from "../../state/application/actions";
import {useWeb3React} from "@web3-react/core";
import {shortenAddress} from "../../blockchain_utility/Address";
import React from "react";
import {formatConnectorName} from "../../blockchain_utility/Wallet";
import {FirebaseDatabaseNode} from "@react-firebase/database";
import Jazzicon from 'react-jazzicon'
import {NftList} from "./NftList";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'center'
    },
    container:{
        outline: 'none',
        maxWidth: '400px',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
    },
    paper: {
        padding: '32px 32px 40px',
    },
    nftAddresses:{
        padding: '0px !important',
        borderRadius: '4px',
        outline: 'none',
        width: '100%',
        textAlign:'left',
        overflowX: 'auto',
        maxHeight: '200px'
    },
    close:{
        width:'20px',
        fontSize: '30px',
        cursor:'pointer',
        textAlign:'right'
    },
    title:{
        fontSize: '30px',
        textAlign:'left'
    },
    modalHeader:{
        marginBottom:'10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    modalBody:{
        borderRadius: '4px',
        border: '1px solid black',
        marginTop: '30px',
        width: '100%',
        height: '130px',
        textAlign:'left'
    },
    link:{
        '&:visited': {
            color:'blue'
        },
    }
}))

export const AccountDetails = () =>{
    const classes = useStyles();
    const isWalletModalOpen = useModalOpen(ApplicationModal.WALLET)
    const closeModals = useCloseModals()
    const {account, connector } = useWeb3React();

    return (
        <Modal open={isWalletModalOpen} className={classes.modal} onClose={closeModals} disableAutoFocus={true}>
            <Fade in={isWalletModalOpen}>
                <div className={classes.container}>
                    <div className={classes.paper}>
                        <div className={classes.modalHeader}>
                            <div className={classes.title}>Account Details</div>
                            <div className={classes.close} onClick={closeModals}>&times;</div>
                        </div>
                        <div className={classes.modalBody}>
                            <div style={{margin:'15px 10px'}}>Connected with {formatConnectorName(connector)}</div>
                            <div style={{margin:'15px 10px'}}>{account &&
                                <div style={{display: 'flex',alignItems:'center'}}>
                                <Jazzicon diameter={20} seed={parseInt(account.slice(2,10),16)} />
                                    &nbsp;
                                <Typography variant='h6' style={{fontWeight: 500}}>{shortenAddress(account)}</Typography>
                                </div>
                            }
                            </div>
                            <div  style={{margin:'15px 10px'}}>
                                <a className={classes.link} href={`https://etherscan.io/address/${account}`} target="_blank" rel="noreferrer">View Account Details on Etherscan</a>
                            </div>
                        </div>
                    </div>
                    <div className={classes.nftAddresses}>
                        <FirebaseDatabaseNode path='blockchain_nft/'>
                            {({ value }) => {
                                if (value === null || typeof value === 'undefined')
                                    return null;

                                return <NftList contractAddresses={Object.keys(value)} tokenIds={value}/>
                            }}
                        </FirebaseDatabaseNode>
                    </div>

                </div>
            </Fade>

        </Modal>
    )
}
