import {useCloseModals, useModalOpen} from "../../state/application/hooks";
import {ApplicationModal} from "../../state/application/actions";
import {CircularProgress, makeStyles, Modal, Typography, Fade} from "@material-ui/core";
import MetaMaskLogo from '../../img/blockchain/metamask.png';
import {useWeb3React} from "@web3-react/core";
import {SUPPORTED_WALLETS} from "../../blockchain_utility/Wallet";
import {useState} from "react";
import ReplayIcon from '@material-ui/icons/Replay';
import {AccountDetails} from "./AccountDetails";


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        textAlign: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        padding: '32px 32px 40px',
        outline: 'none',
        maxWidth: '400px',
        width: '100%'
    },
    walletConnectionButton: {
        marginBottom:'30px',
        marginTop: '30px',
        width: '100%',
        border: 'none',
        backgroundImage: 'linear-gradient(267.54deg, rgb(255, 220, 36) 1.63%, rgb(255, 92, 0) 98.05%)',
        borderRadius: '4px',
        textAlign: 'left',
        color: 'white',
        padding: '16px 24px',
        fontWeight: '600',
        height: '60px',
        cursor: 'pointer',
        fontSize: '16px',
        boxSizing: 'border-box',
        transition: 'all 300ms cubic-bezier(0.23, 1, 0.32, 1) 0s',
        '&:hover': {
            boxShadow: 'rgb(0 0 0 / 25%) 0px 8px 15px',
            transform: 'translateY(-2px)'
        },
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: '30px',
        outline:'none'
    },
    walletLogo: {
        height: '30px'
    },
    close:{
       marginLeft:'auto',
        marginRight:'0',
        width:'20px',
        fontSize: '30px',
        marginBottom:'10px',
        cursor:'pointer',
        textAlign:'right'
    },
    loader:{
        color:"white"
    },
    link:{
        '&:visited': {
            color:'blue'
        },
    }
}))

const WALLET_VIEWS = {
    OPTIONS: 'options',
    ACCOUNT: 'account',
    PENDING: 'pending'
}


export const WalletModal = () => {
    const classes = useStyles();
    const metamaskWallet = SUPPORTED_WALLETS.METAMASK;
    const isWalletModalOpen = useModalOpen(ApplicationModal.WALLET)
    const closeModals = useCloseModals()
    const [pendingError, setPendingError] = useState()
    const [walletView, setWalletView] = useState(WALLET_VIEWS.OPTIONS)

    const { account, connector, activate } = useWeb3React()

    const tryActivation = async (connector) => {
        setPendingError(false);
        setWalletView(WALLET_VIEWS.PENDING);
        try {
            await activate(connector, undefined, true);
            setPendingError(false);
            setWalletView(WALLET_VIEWS.ACCOUNT);
            closeModals();
        } catch (error) {
            setPendingError(true);
            console.log(error)
        }
    }

    if(account && walletView === WALLET_VIEWS.ACCOUNT)
        return (
            <AccountDetails />
        )
    return (
        <Modal open={isWalletModalOpen} className={classes.modal} onClose={closeModals} disableAutoFocus={true}>
            <Fade in={isWalletModalOpen}>
                <div className={classes.paper}>
                    <div className={classes.close} onClick={closeModals}>&times;</div>
                    <Typography variant='h4' style={{fontWeight: 400}}>Connect your wallet.</Typography>
                    <button className={classes.walletConnectionButton} onClick={async () => await tryActivation(metamaskWallet.connector)}>
                        {
                            walletView === WALLET_VIEWS.OPTIONS ?
                                <>
                                    MetaMask
                                    <img className={classes.walletLogo} alt={"Metamask Logo"} src={MetaMaskLogo}/>
                                </>
                            :
                                walletView === WALLET_VIEWS.PENDING &&
                                    pendingError ?
                                        <>
                                            Error connecting
                                            <ReplayIcon onClick={async () => await tryActivation(connector)} />
                                        </>
                                    :
                                        <>
                                            Initializing...
                                            <CircularProgress className={classes.loader} size={30} />
                                        </>
                        }
                    </button>
                    <div>
                        <span>New to Ethereum? &nbsp;</span>{' '}
                        <a className={classes.link} href="https://ethereum.org/wallets/">Learn more about wallets</a>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}
