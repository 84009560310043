import { Divider, Icon, IconButton, Link, makeStyles, Toolbar, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Instagram from '@material-ui/icons/Instagram'
import Twitter from '@material-ui/icons/Twitter'
import translator from "../utility/translator";
import MailOutline from "@material-ui/icons/MailOutline";
import { Spacer } from "./Spacer";

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        width: '100%',
        padding: '10px',
    }
}));

const SocialIcons = () => {
    const theme = useTheme()
    return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant='body1'>{translator.fromLabel('home_contactUs_label')}</Typography>
        <Spacer width={15}/> 
        <Link href='https://twitter.com/OwnshowSpace' target='_blank' rel='noopener'>
            <IconButton size='small' style={{ margin: '0px 5px' }}>
                <Twitter style={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
        </Link>
        <Link href='https://www.instagram.com/ownshow.space' target='_blank' rel='noopener'>
            <IconButton size='small' style={{ margin: '0px 5px' }}>
                <Instagram style={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
        </Link>
        <Link href='https://discord.gg/G6Efj4vE' target='_blank' rel='noopener'>
            <IconButton size='small' style={{ margin: '0px 5px' }}>
                <Icon style={{}}>
                    <img src="/discord.svg" alt='discord'/>
                </Icon>
            </IconButton>
        </Link>
        <a href='mailto:ownshow.space@gmail.com' target='_blank' rel="noopener noreferrer">
            <IconButton size='small' style={{ margin: '0px 5px' }}>
                <MailOutline style={{ color: theme.palette.primary.contrastText }} />
            </IconButton>
        </a>
    </div>
}

const Love = () => {
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant='caption' style={{ margin: '7px 0px' }}>Powered by <Link color='textPrimary' href='https://bytes.black'>BlackBytes</Link> </Typography>
    </div>
}

const Copyright = () => {
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant='body2' style={{ margin: '0px 7px' }}>Beta Version</Typography>
        <Typography variant='body2' style={{ margin: '0px 7px' }}>{`© ${new Date().getFullYear()} ${translator.fromLabel('home_copyright_text')}`}</Typography>
    </div>
}

export const Footer = () => {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down('xs'))

    const classes = useStyles()
    return <Toolbar disableGutters className={classes.footer}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <Divider color='red' orientation='horizontal' variant='fullWidth' style={{ width: '100%', height: 2 }} />
            <div style={{ display: 'flex', flexDirection: mobile?'column':'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant='body1'>{translator.fromLabel('home_companyName_text')}</Typography>
                <SocialIcons />
            </div>
            <Divider orientation='horizontal' variant='fullWidth' style={{ width: '100%', height: 2, backgroundColor: theme.palette.primary }} />
            <Spacer height={5}/>
            <Copyright />
            <Love />
        </div>
    </Toolbar>
}
