import { useTheme } from "@material-ui/core"
import { Link } from "react-router-dom"
import logoSvg from '../img/logo.svg'

export const Logo = () => {
    // TODO theming
    const theme = useTheme()
    return <Link to={'/'}>
        <div style={{ width: 180, height: 55, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundImage: `url('${logoSvg}')`, backgroundColor: theme.palette.background.default }} />
    </Link>
}